import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API, { baseURL } from '../api/api';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Avatar,
  Grid,
  Box,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';

const MyChallenges = () => {
  const { currentUser } = useAuth();
  const [challenges, setChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    const fetchMyChallenges = async () => {
      try {
        if (!currentUser) {
          setIsLoading(false);
          return;
        }

        const response = await API.get('/club/challenges/users/');
        let userChallenges = response.data;

        if (Array.isArray(response.data.results)) {
          userChallenges = response.data.results;
        }

        if (!Array.isArray(userChallenges)) {
          console.error('Unexpected API response format:', response.data);
          setIsLoading(false);
          return;
        }

        const challengesData = userChallenges.map(
          (userChallenge) => userChallenge.challenge
        );

        setChallenges(challengesData);
      } catch (error) {
        console.error('Failed to fetch user challenges:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMyChallenges();
  }, [currentUser]);

  const handleLeaveChallenge = async (challengeId) => {
    try {
      await API.delete(`/club/challenges/${challengeId}/users/`); // Fixed endpoint
      setChallenges(challenges.filter(challenge => challenge.id !== challengeId));
      setSnackbar({
        open: true,
        message: 'Sėkmingai atsisakėte iššūkio',
        severity: 'success'
      });
    } catch (error) {
      console.error('Failed to leave challenge:', error);
      setSnackbar({
        open: true,
        message: 'Nepavyko atsisakyti iššūkio',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Mano skaitymo iššūkiai
        </Typography>
        <Typography variant="body1">
          Norėdami matyti savo skaitymo iššūkius, turite prisijungti
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Mano skaitymo iššūkiai
      </Typography>
      {challenges.length > 0 ? (
        <List>
          {challenges.map((challenge) => (
            <ListItem
              key={challenge.id}
              sx={{
                backgroundColor: 'secondary.main',
                marginBottom: '1rem',
                padding: '1rem',
                borderRadius: '8px',
                color: 'text.primary',
                display: 'block'
              }}
            >
              <Box 
                component={Link} 
                to={`/club/challenges/${challenge.id}`}
                sx={{ 
                  textDecoration: 'none',
                  color: 'inherit',
                  display: 'block'
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box display="flex" flexDirection="row">
                      {challenge.books.map((book) => {
                        const coverURL = book.cover
                          ? book.cover.startsWith('http')
                            ? book.cover
                            : `${baseURL}${book.cover}`
                          : '/default_cover.jpg';

                        return (
                          <Avatar
                            key={book.id}
                            src={coverURL}
                            alt={book.title}
                            sx={{ width: 100, height: 150, marginRight: '8px' }}
                            variant="rounded"
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                          {challenge.name}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleLeaveChallenge(challenge.id)}
                >
                  Palikti iššūkį
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          Kol kas nepasiryžote jokiam skaitymo iššūkiui
        </Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MyChallenges;
