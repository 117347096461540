import React from 'react';
import { AppBar, Toolbar, IconButton, Container, Typography, Box } from '@mui/material';
import { Nature, NaturePeople, ExitToApp, Login, AccountCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HelpIcon from '@mui/icons-material/Help';
import LapasPoLapoLogo from '../assets/lapas_navbar.png';

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'primary.main' }}>
      <Container>
        <Toolbar>
          <Box display="flex" alignItems="center">
            {/* Add the logo */}
            <img src={LapasPoLapoLogo} alt="Lapas po lapo Logo" style={{ width: 40, height: 40, marginRight: '10px' }} />
            {/* Add the website name */}
            <Typography variant="h6" component={Link} to="/" color="inherit" sx={{ textDecoration: 'none' }}>
              Lapas po lapo
            </Typography>
          </Box>
          
          {/* Existing navigation options */}
          <Box sx={{ flexGrow: 1 }} />
          {isAuthenticated ? (
            <>
              <IconButton color="inherit" component={Link} to="/all-challenges">
                <Nature />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/my-challenges">
                <NaturePeople />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/help">
                <HelpIcon />
              </IconButton>
              <IconButton color="inherit" component={Link} to="/user-profile">
                <AccountCircle />
              </IconButton>
              <IconButton color="inherit" onClick={handleLogout}>
                <ExitToApp />
              </IconButton>
            </>
          ) : (
            <IconButton color="inherit" component={Link} to="/login">
              <Login />
            </IconButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
