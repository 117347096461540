import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';
import API from '../api/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      await API.post('/users/password-reset/', { email });
      setSuccessMessage('El. laiškas dėl slaptažodžio atstatymo buvo išsiųstas.');
      setEmail('');  // Clear the email input
    } catch (error) {
      setErrorMessage('Nepavyko išsiųsti laiško. Patikrinkite el. paštą ir bandykite dar kartą.');
      console.error('Error requesting password reset:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 4,
        p: 2,
        border: '1px solid #ccc',
        borderRadius: 2,
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" mb={2}>Slaptažodžio atstatymas</Typography>
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="El. paštas"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitting}
          sx={{ mt: 2 }}
        >
          {isSubmitting ? 'Siunčiama...' : 'Atstatyti slaptažodį'}
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
