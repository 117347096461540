// SupportDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Button, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../api/api';

const SupportDetail = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRequestDetail = async () => {
      try {
        let endpoint = '';

        switch (type) {
          case 'bug-report':
            endpoint = `/support/bug-reports/${id}/`;
            break;
          case 'feature-proposal':
            endpoint = `/support/feature-proposals/${id}/`;
            break;
          case 'general-ticket':
            endpoint = `/support/tickets/${id}/`;
            break;
          default:
            throw new Error('Invalid request type');
        }

        const response = await API.get(endpoint);
        setRequest(response.data);
      } catch (error) {
        console.error(error);
        setError('Nepavyko gauti užklausos duomenų');
      } finally {
        setLoading(false);
      }
    };

    fetchRequestDetail();
  }, [id, type]);

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h5" mt={4}>
          {error}
        </Typography>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Grįžti
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" gutterBottom>
          Užklausos detalės
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Grįžti
        </Button>
      </Stack>
      {request && (
        <>
          <Typography variant="h6" gutterBottom>
            Tema: {request.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Aprašymas: {request.description}
          </Typography>
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              Sukurta: {new Date(request.created_at).toLocaleString('lt-LT', { timeZone: 'Europe/Vilnius' })}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Būsena:{' '}
              {request.is_resolved ? (
                <span style={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                  Išspręsta <CheckCircleIcon sx={{ ml: 0.5 }} />
                </span>
              ) : (
                <span style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                  Neišspręsta <CancelIcon sx={{ ml: 0.5 }} />
                </span>
              )}
            </Typography>
            {request.severity && (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Prioritetas: {request.severity}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Container>
  );
};

export default SupportDetail;
