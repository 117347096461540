import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Help = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ticketType, setTicketType] = useState('bug-report');
  const { currentUser } = useAuth();
  const [successMessage, setSuccessMessage] = useState('');
  const [requests, setRequests] = useState([]);
  const navigate = useNavigate();

  // Fetch all existing help requests
  const fetchRequests = async () => {
    try {
      const [bugReports, featureProposals, generalTickets] = await Promise.all([
        API.get('/support/bug-reports/'),
        API.get('/support/feature-proposals/'),
        API.get('/support/tickets/'),
      ]);

      // Combine and map requests with type identifiers
      const allRequests = [
        ...bugReports.data.results.map((request) => ({ ...request, type: 'bug-report', typeLabel: 'Pranešti apie problemą' })),
        ...featureProposals.data.results.map((request) => ({ ...request, type: 'feature-proposal', typeLabel: 'Pasiūlyti funkcionalumą' })),
        ...generalTickets.data.results.map((request) => ({ ...request, type: 'general-ticket', typeLabel: 'Kita' })),
      ];

      setRequests(allRequests);
    } catch (error) {
      console.error('Failed to fetch help requests:', error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpointMap = {
      'bug-report': '/support/bug-reports/',
      'feature-proposal': '/support/feature-proposals/',
      'general-ticket': '/support/tickets/',
    };

    const body = {
      user: currentUser.id,
      title,
      description,
    };

    try {
      await API.post(endpointMap[ticketType], body);
      setSuccessMessage('Prašymas sėkmingai pateiktas!');
      setTitle('');
      setDescription('');
      await fetchRequests();
    } catch (error) {
      console.error('Failed to submit ticket:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Susisiekite su mumis
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tema"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          label="Jūsų žinutė"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          margin="normal"
          multiline
          rows={4}
        />
        <RadioGroup value={ticketType} onChange={(e) => setTicketType(e.target.value)} row>
          <FormControlLabel value="bug-report" control={<Radio />} label="Pranešti apie problemą" />
          <FormControlLabel value="feature-proposal" control={<Radio />} label="Pasiūlyti funkcionalumą" />
          <FormControlLabel value="general-ticket" control={<Radio />} label="Kita" />
        </RadioGroup>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Pateikti
        </Button>
        {successMessage && (
          <Typography color="success" mt={2}>
            {successMessage}
          </Typography>
        )}
      </form>

      {/* Display requests in a table */}
      <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem' }}>
        Užregistruotos žinutės
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipas</TableCell>
              <TableCell>Tema</TableCell>
              <TableCell>Būsena</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow
                key={`${request.type}-${request.id}`}
                hover
                onClick={() => navigate(`/support/requests/${request.type}/${request.id}`)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{request.typeLabel}</TableCell>
                <TableCell>{request.title}</TableCell>
                <TableCell>
                  {request.is_resolved ? (
                    <CheckCircleIcon sx={{ color: 'green' }} />
                  ) : (
                    <CancelIcon sx={{ color: 'red' }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Help;
