import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6A9C89',  // Light green color
    },
    secondary: {
      main: '#C4DAD2',  // Light grey-green
    },
    background: {
      default: '#E9EFEC',  // Background color
    },
    text: {
      primary: '#16423C',  // Dark green text
      secondary: '#E9EFEC',  // Light text for contrast
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#16423C',  // Dark green
      marginBottom: '0.5rem',  // Reduced margin for balanced spacing
    },
    body1: {
      fontSize: '1rem',
      color: '#16423C',  // Body text color
    },
    subtitle1: {
      fontSize: '1.25rem',
      color: '#16423C',
    },
  },
  spacing: 8,  // Default spacing unit
  shadows: ['none', '0px 4px 6px rgba(0, 0, 0, 0.1)'],  // Custom shadow for elements

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#6A9C89',  // Primary button color
          color: '#E9EFEC',  // Light text on buttons
          '&:hover': {
            backgroundColor: '#5C887A',  // Slightly darker on hover
          },
        },
        outlined: {
          borderColor: '#6A9C89',
          color: '#6A9C89',
          '&:hover': {
            borderColor: '#5C887A',
            color: '#5C887A',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.5rem',  // Reduced margin for tighter spacing
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#C4DAD2',  // Background for list items
          borderRadius: '8px',
          margin: '0.5rem',  // Equal margin on all sides (top, bottom, left, right)
          padding: '0.5rem',  // Equal padding inside the ListItem
          color: '#16423C',  // Dark text
          '&:hover': {
            backgroundColor: '#B8D1CA',  // Slightly lighter background on hover
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          marginRight: '0.25rem',  // Reduced gap between avatars
        },
        rounded: {
          borderRadius: '8px',
          width: '100px',
          height: '150px',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          gap: '0.5rem',  // Reduced gap between elements inside a Box (e.g., between book covers)
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          spacing: 1,  // Reduced space between grid items
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0.5rem',  // Reduced padding in dialogs
          borderRadius: '10px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '0.75rem',  // Reduced space between form elements
        },
      },
    },
  },
});

export default theme;
