import React, { useState } from 'react';
import { TextField, Checkbox, Button, FormControlLabel, Box, Typography } from '@mui/material';
import API from '../api/api';

const Registration = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeNewsUpdates, setAgreeNewsUpdates] = useState(true);
  const [agreeNewsletters, setAgreeNewsletters] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  const handleRegister = async () => {
    if (!agreeTerms) {
      setErrorMessage('Privalote sutikti su sąlygomis ir nuostatomis.');
      return;
    }

    if (password !== passwordVerification) {
      setErrorMessage('Slaptažodžiai nesutampa.');
      return;
    }

    try {
      await API.post('/users/register/', {
        email,
        username,
        password,
        confirm_password: passwordVerification,
        agreed_to_terms: agreeTerms,
        agreed_to_newsletter: agreeNewsletters,
        agreed_to_internal_communication: agreeNewsUpdates,
      });
      setErrorMessage('');
      setIsRegistered(true); // Mark registration as successful
    } catch (error) {
      setErrorMessage('Registracija nepavyko. Bandykite dar kartą.');
      console.error('Registration error:', error);
    }
  };

  if (isRegistered) {
    return (
      <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 2, textAlign: 'center' }}>
        <Typography variant="h4" mb={2}>Registracija sėkminga!</Typography>
        <Typography variant="body1" mb={2}>
          Greitai turėtumėte gauti patvirtinimo laišką el. paštu.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
      <Typography variant="h4" mb={2}>Registruotis</Typography>
      
      <TextField
        label="El. paštas"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Vartotojo vardas"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Slaptažodis"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        label="Patvirtinti slaptažodį"
        type="password"
        fullWidth
        margin="normal"
        value={passwordVerification}
        onChange={(e) => setPasswordVerification(e.target.value)}
      />

      {/* Checkboxes */}
      <FormControlLabel
        control={
          <Checkbox
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
          />
        }
        label="Sutinku su sąlygomis ir nuostatomis (privaloma)"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={agreeNewsUpdates}
            onChange={(e) => setAgreeNewsUpdates(e.target.checked)}
          />
        }
        label="Sutinku gauti pranešimus iš vartotojų ir administratorių"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={agreeNewsletters}
            onChange={(e) => setAgreeNewsletters(e.target.checked)}
          />
        }
        label="Sutinku gauti naujienlaiškius"
      />

      {/* Error Message */}
      {errorMessage && <Typography color="error" mb={2}>{errorMessage}</Typography>}

      <Button variant="contained" color="primary" fullWidth onClick={handleRegister}>
        Registruotis
      </Button>
    </Box>
  );
};

export default Registration;
