import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';

const UserProfile = () => {
  const [user, setUser] = useState({ first_name: '', last_name: '', email: '' });
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await API.get('/users/current-user/');
        setUser(response.data);
      } catch (error) {
        console.error('Klaida gaunant naudotojo duomenis:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handlePasswordChange = async () => {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage('Visi slaptažodžio laukai turi būti užpildyti');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Slaptažodžiai nesutampa');
      return;
    }

    try {
      const response = await API.post('/users/change-password/', {
        old_password: oldPassword,
        password: newPassword,
        confirm_password: confirmNewPassword,
      });
      if (response.status === 200) {
        setSuccessMessage('Slaptažodis sėkmingai atnaujintas');
        setErrorMessage('');
      } else if (response.status === 201) {
        logout();
        navigate('/login?password_changed=true');
      }
    } catch (error) {
      console.error('Klaida atnaujinant slaptažodį:', error);
      if (error.response) {
        if (Array.isArray(error.response.data.old_password) && error.response.data.old_password.includes('error_password_is_incorrect')) {
          setErrorMessage('Senas slaptažodis yra neteisingas');
        } else if (error.response.data.detail) {
          setErrorMessage(error.response.data.detail);
        } else {
          setErrorMessage('Nepavyko atnaujinti slaptažodžio');
        }
      } else {
        setErrorMessage('Nepavyko atnaujinti slaptažodžio');
      }
    }
  };

  if (loading) {
    return <Typography>Kraunama...</Typography>;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Naudotojo profilis
        </Typography>
        <Typography variant="body1">
          <strong>Vardas:</strong> {user.first_name}
        </Typography>
        <Typography variant="body1">
          <strong>Pavardė:</strong> {user.last_name}
        </Typography>
        <Typography variant="body1">
          <strong>El. paštas:</strong> {user.email}
        </Typography>

        <Box sx={{ marginTop: '2rem' }}>
          <Typography variant="h5" gutterBottom>
            Pakeisti slaptažodį
          </Typography>
          <TextField
            label="Senas slaptažodis"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            fullWidth
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Naujas slaptažodis"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Patvirtinti naują slaptažodį"
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            fullWidth
            sx={{ marginBottom: '1rem' }}
          />
          <Button variant="contained" color="primary" onClick={handlePasswordChange}>
            Atnaujinti slaptažodį
          </Button>
          {successMessage && (
            <Typography color="primary" sx={{ marginTop: '1rem' }}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography color="error" sx={{ marginTop: '1rem' }}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
