import React, { useEffect, useState } from 'react';
import API from '../api/api';
import {
  Typography,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const BookComments = ({ bookId, currentUser }) => {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(true);
  const [commentContent, setCommentContent] = useState('');
  const [postingComment, setPostingComment] = useState(false);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await API.get(`/club/challenge_books/${bookId}/comments/`);
        setComments(response.data);
      } catch (error) {
        console.error('Klaida nuskaitant komentarus:', error);
      } finally {
        setLoadingComments(false);
      }
    };

    fetchComments();
  }, [bookId]);

  const handlePostComment = async () => {
    if (!commentContent.trim()) {
      return;
    }

    setPostingComment(true);
    try {
      const response = await API.post(`/club/challenge_books/${bookId}/comments/`, {
        content: commentContent,
      });
      setComments((prevComments) => [...prevComments, response.data]);
      setCommentContent('');
    } catch (error) {
      console.error('Klaida kuriant komentarą:', error);
    } finally {
      setPostingComment(false);
    }
  };

  const handleDeleteComment = async () => {
    if (!deletingCommentId) {
      return;
    }

    try {
      await API.delete(`/club/challenge_books/${bookId}/comments/${deletingCommentId}/`);
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== deletingCommentId)
      );
      setConfirmDeleteOpen(false);
      setDeletingCommentId(null);
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };

  const openConfirmDeleteDialog = (commentId) => {
    setDeletingCommentId(commentId);
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
    setDeletingCommentId(null);
  };

  return (
    <Box mt={4} width="100%">
      <Typography variant="h6" gutterBottom>
        Tavo komentarai
      </Typography>
      {loadingComments ? (
        <Typography variant="body2">Komentarai ruošiami...</Typography>
      ) : comments.length > 0 ? (
        <List>
          {comments.map((comment) => (
            <ListItem key={comment.id} alignItems="flex-start">
              <ListItemText primary={comment.content} />
              <IconButton edge="end" aria-label="delete" onClick={() => openConfirmDeleteDialog(comment.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2">Nėra komentarų</Typography>
      )}

      {currentUser ? (
        <Box mt={2}>
          <TextField
            label="Pridėti komentarą"
            multiline
            fullWidth
            minRows={2}
            variant="outlined"
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
          />
          <Box mt={1} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostComment}
              disabled={postingComment}
            >
              {postingComment ? 'Komentaras saugomas...' : 'Išsaugoti komentarą'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography variant="body2" mt={2}>
          Jei norite rašyti komentarą, turite būti prisijungęs(usi)
        </Typography>
      )}

      {/* Confirmation Dialog */}
      <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog} aria-labelledby="confirm-delete-dialog-title">
        <DialogTitle id="confirm-delete-dialog-title">Patvirtinkite veiksmą</DialogTitle>
        <DialogContent>
          <DialogContentText>Ar tikrai norite ištrinti komentarą?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDeleteDialog} color="primary">
            Ne
          </Button>
          <Button onClick={handleDeleteComment} color="secondary">
            Taip
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookComments;
