import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API, { baseURL } from '../api/api';
import {
  Button,
  Typography,
  Container,
  CircularProgress,
  Grid,
  Box,
  Avatar,
  IconButton,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import BookComments from './BookComments';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';

const ChallengeDetail = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const [challenge, setChallenge] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [hasJoined, setHasJoined] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkJoinedStatus = async (challengeId) => {
      try {
        const joinedUsersResponse = await API.get(`/club/challenges/${challengeId}/users/`);
        console.log('Joined users response:', joinedUsersResponse.data); // Debug log
        if (Array.isArray(joinedUsersResponse.data)) {
          const joinedUserIds = joinedUsersResponse.data.map(user => user.id);
          const isJoined = joinedUserIds.includes(currentUser?.id);
          console.log('User joined status:', isJoined); // Debug log
          return isJoined;
        }
        return false;
      } catch (error) {
        console.error('Failed to check joined status:', error);
        return false;
      }
    };

    const fetchChallengeDetails = async () => {
      try {
        setLoading(true);
        const [challengeResponse, joinedStatus] = await Promise.all([
          API.get(`/club/challenges/${id}/`),
          currentUser ? checkJoinedStatus(id) : Promise.resolve(false)
        ]);

        setChallenge(challengeResponse.data);
        setHasJoined(joinedStatus);

        if (challengeResponse.data.books?.length > 0) {
          setSelectedBook(challengeResponse.data.books[0]);
        }
      } catch (error) {
        console.error('Failed to fetch challenge details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChallengeDetails();
  }, [id, currentUser]);

  const handleJoinChallenge = async () => {
    try {
      await API.post(`/club/challenges/${id}/users/`);
      setHasJoined(true);
    } catch (error) {
      console.error('Failed to join challenge:', error);
    }
  };

  const handleLeaveChallenge = async () => {
    try {
      await API.delete(`/club/challenges/${id}/users/`);
      setHasJoined(false);
      setSelectedBook(null);
    } catch (error) {
      console.error('Failed to leave challenge:', error);
    }
  };

  const handleBookSelection = (book) => {
    if (selectedBook && book.id === selectedBook.id) {
      return;
    }
    setSelectedBook(book);
  };

  const getCoverURL = (cover) => {
    const coverPath = cover ? cover.replace(/^https?:\/\/[^/]+/, '') : '';
    return coverPath ? `${baseURL}${coverPath}` : '/default_cover.jpg';
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        {challenge.name}
      </Typography>

      {currentUser ? (
        !hasJoined ? (
          <Button variant="contained" color="primary" onClick={handleJoinChallenge}>
            Dalyvauti
          </Button>
        ) : (
          <Button variant="contained" color="error" onClick={handleLeaveChallenge}>
            Palikti iššūkį
          </Button>
        )
      ) : (
        <Typography variant="body1" gutterBottom>
          Norėdami dalyvauti šiame iššūkyje, prisijunkite
        </Typography>
      )}

      {challenge.books && challenge.books.length > 0 && (
        <Box mt={4}>
          {hasJoined ? (
            <LayoutGroup>
              <Grid container spacing={2} justifyContent="center">
                {challenge.books
                  .filter((book) => book.id !== selectedBook?.id)
                  .map((book) => (
                    <Grid item key={book.id}>
                      <IconButton
                        onClick={() => handleBookSelection(book)}
                        color="default"
                        sx={{
                          '&:hover .MuiAvatar-root': {
                            transform: 'scale(1.1)',
                            borderRadius: '4px',
                          },
                        }}
                      >
                        <motion.div
                          layoutId={`avatar-${book.id}`}
                          transition={{ duration: 0.5, ease: 'easeInOut' }}
                        >
                          <Avatar
                            src={getCoverURL(book.cover)}
                            alt={`Cover of ${book.title}`}
                            sx={{ width: 90, height: 135, transition: 'all 0.3s ease' }}
                            variant="rounded"
                          />
                        </motion.div>
                      </IconButton>
                    </Grid>
                  ))}
              </Grid>

              <AnimatePresence>
                {selectedBook && (
                  <Box mt={4} position="relative">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <motion.div
                        key={`cover-${selectedBook.id}`}
                        layoutId={`avatar-${selectedBook.id}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                      >
                        <Avatar
                          src={getCoverURL(selectedBook.cover)}
                          alt={`Cover of ${selectedBook.title}`}
                          sx={{ width: 150, height: 225 }}
                          variant="rounded"
                        />
                      </motion.div>
                      <Typography variant="h6" mt={2} align="center">
                        {selectedBook.title}
                      </Typography>
                      <Typography variant="body2" align="center">
                        {selectedBook.authors.map(
                          (author) => `${author.first_name} ${author.last_name}`
                        )}
                      </Typography>
                      <BookComments bookId={selectedBook.id} currentUser={currentUser} />
                    </Box>
                  </Box>
                )}
              </AnimatePresence>
            </LayoutGroup>
          ) : (
            <Grid container spacing={2}>
              {challenge.books.map((book) => (
                <Grid item key={book.id} xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar
                      src={getCoverURL(book.cover)}
                      alt={`Cover of ${book.title}`}
                      sx={{ width: 100, height: 150 }}
                      variant="rounded"
                    />
                    <Typography variant="h6" mt={2} align="center">
                      {book.title}
                    </Typography>
                    <Typography variant="body2" align="center">
                      {book.authors.map((author) => `${author.first_name} ${author.last_name}`).join(', ')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ChallengeDetail;
