import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [currentUser, setCurrentUser] = useState(null);

  // Function to log in and fetch current user
  const login = async (token, refresh) => {
    try {
      // Store tokens in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refresh);
      
      // Set authentication state to true
      setIsAuthenticated(true);

      // Fetch the current user data
      const userResponse = await API.get('/users/current-user/');
      setCurrentUser(userResponse.data);  // Store current user data in state
    } catch (error) {
      console.error('Failed to fetch current user data after login:', error);
      setIsAuthenticated(false);
    }
  };

  // Function to log out and clear user data
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setCurrentUser(null);  // Clear current user data
  };

  // On mount, check if user is authenticated by checking token in localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      API.get('/users/current-user/')
        .then((response) => {
          setCurrentUser(response.data);
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.error('Failed to fetch current user on page load:', error);
          setIsAuthenticated(false);
        });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
