// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Help from './components/Help';
import AllChallenges from './components/AllChallenges';
import MyChallenges from './components/MyChallenges';
import ChallengeDetail from './components/ChallengeDetail';
import SupportDetail from './components/SupportDetail';
import FrontPage from './components/FrontPage';
import Registration from './components/Registration';
import UserProfile from './components/UserProfile'; 
import ForgotPassword from './components/ForgotPassword';
import { AuthProvider, useAuth } from './context/AuthContext';
import theme from './theme';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <AuthWrapper />
      </ThemeProvider>
    </AuthProvider>
  );
}

const AuthWrapper = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Routes>
        {/* Set FrontPage as the default page */}
        <Route path="/" element={<FrontPage />} />  
        <Route path="/all-challenges" element={<AllChallenges />} />
        <Route path="/my-challenges" element={<MyChallenges />} />
        <Route path="/club/challenges/:id" element={<ChallengeDetail />} />
        <Route path="/help" element={<Help />} /> 
        <Route path="/support/requests/:type/:id" element={<SupportDetail />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        
        {!isAuthenticated ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        ) : null}
      </Routes>
    </>
  );
};

export default App;
